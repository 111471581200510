import { PopoverButton, PopoverPanel, Transition } from '@headlessui/react';

import { ShoppingBasket, XIcon } from 'lucide-react';
import { Link } from 'react-router';

import { mobileLinks } from '../../constants';

type Props = {
  itemsInCart: number;
};

export function MobileNavigation(props: Props) {
  return (
    <Transition
      enter='duration-200 ease-out'
      enterFrom='opacity-0 scale-95'
      enterTo='opacity-100 scale-100'
      leave='duration-100 ease-in'
      leaveFrom='opacity-100 scale-100'
      leaveTo='opacity-0 scale-95'>
      <PopoverPanel
        focus={true}
        className='absolute inset-x-0 top-0 z-50 origin-top-left transform p-2 transition md:hidden'>
        <div className='divide-y-2 divide-neutral-50 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5'>
          <div className='px-5 pb-6 pt-5'>
            <div className='flex items-center justify-between'>
              <div className='-mr-2 pr-5'>
                <PopoverButton className='mr-px inline-flex items-center justify-center rounded-md bg-white p-2 text-neutral-500 hover:bg-neutral-100 hover:text-neutral-600 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-brand-500'>
                  <span className='sr-only'>Close menu</span>

                  <XIcon className='h-6 w-6' aria-hidden='true' />
                </PopoverButton>
              </div>

              <div className='-mr-px'>
                <PopoverButton as={Link} to='/'>
                  <img
                    className='h-10 w-auto'
                    src='/images/sump-logo.svg'
                    alt='Shape Up Meal Prep Logo'
                  />
                </PopoverButton>
              </div>

              <div>
                <PopoverButton
                  as={Link}
                  to='/cart'
                  className='inline-flex items-center justify-center rounded-md bg-white p-2 text-neutral-500 hover:bg-neutral-100 hover:text-neutral-600 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-brand-500'>
                  <span className='sr-only'>Go to cart</span>

                  <ShoppingBasket className='h-6 w-6' aria-hidden='true' />

                  <span className='ml-3 font-medium'>{props.itemsInCart}</span>
                </PopoverButton>
              </div>
            </div>

            <div className='mt-6'>
              <nav className='grid grid-cols-1 gap-7'>
                {mobileLinks.packages.map((item) => (
                  <PopoverButton
                    as={Link}
                    key={item.name}
                    to={item.href}
                    className='-m-3 flex items-center rounded-lg p-3 hover:bg-neutral-50'>
                    <div className='flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-xl bg-brand-200 text-brand-900'>
                      <item.icon className='h-5 w-5' aria-hidden='true' />
                    </div>

                    <div className='ml-4 text-base font-medium text-neutral-900'>
                      {item.name}
                    </div>
                  </PopoverButton>
                ))}
              </nav>
            </div>
          </div>

          <div className='px-5 py-6'>
            <div className='grid grid-cols-2 gap-4 text-left'>
              {mobileLinks.main.map((route) => (
                <PopoverButton
                  key={route.name}
                  as={Link}
                  to={route.href}
                  className='text-base font-medium text-neutral-900 hover:text-neutral-700'>
                  {route.name}
                </PopoverButton>
              ))}
            </div>

            {/* <div className='mt-6'>
                <a
                  href='#'
                  className='flex w-full items-center justify-center rounded-md border border-transparent bg-brand-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-brand-700'>
                  Sign up
                </a>

                <p className='mt-6 text-center text-base font-medium text-neutral-500'>
                  Existing customer?{' '}
                  <a
                    href='#'
                    className='text-brand-600 hover:text-brand-500'>
                    Sign in
                  </a>
                </p>
              </div> */}
          </div>
        </div>
      </PopoverPanel>
    </Transition>
  );
}
