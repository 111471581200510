import type { Moment } from 'moment-timezone';

type GetDeliveryDateData = {
  orderConfirmedDate: Moment;
  deliveryDay: 'Sunday' | 'Monday';
};

enum DAY_OF_WEEK {
  MONDAY = 1,
  TUESDAY,
  WEDNESDAY,
  THURSDAY,
  FRIDAY,
  SATURDAY,
  SUNDAY,
}

const isOnOrBeforeRealCutoff = (orderConfirmedDate: Moment) => {
  const dayOfWeek = orderConfirmedDate.day();
  const hour = orderConfirmedDate.hour();

  // Return true if it's any day before Saturday and AFTER Sunday
  if (dayOfWeek > 0 && dayOfWeek < 6) {
    return true;
  }

  // Return true if it's Saturday and the hour is 19 (7pm) or earlier
  // Note that because the hour starts at a 0 index, we needed to
  // make sure that we deducted 1 (hence hour 18).
  if (dayOfWeek === 6 && hour <= 18) {
    return true;
  }

  return false;
};

/**
 * Handle calculating the correct delivery date for newly created orders.
 * @deprecated prefer not to use this method anymore.
 *
 * @param data The confirmation date and the delivery day that was selected.
 *
 * @returns The delivery date contained in a Moment instance.
 */
export const getDeliveryDate = ({
  orderConfirmedDate,
  deliveryDay,
}: GetDeliveryDateData) => {
  // const confirmedDate = orderConfirmedDate.isoWeekday();
  const isSunday = deliveryDay === 'Sunday';

  let date: Moment;

  // If the customer has placed their order before the cut off date, then we
  // want to make sure they are going to receive their order in the coming
  // round of deliveries.
  if (isOnOrBeforeRealCutoff(orderConfirmedDate)) {
    date = orderConfirmedDate
      .clone()
      .startOf('day')
      .add(isSunday ? '0' : '1', 'weeks')
      .set('isoWeekday', isSunday ? DAY_OF_WEEK.SUNDAY : DAY_OF_WEEK.MONDAY);
  } else {
    // Otherwise, the customers' order will be pushed back by a week as they
    // ordered AFTER the cut off date.
    date = orderConfirmedDate
      .clone()
      .startOf('day')
      .add(isSunday ? '1' : '2', 'weeks')
      .set('isoWeekday', isSunday ? DAY_OF_WEEK.SUNDAY : DAY_OF_WEEK.MONDAY);
  }

  // This is to ensure that the date we receive is always ahead of the order
  // date.
  if (date.isBefore(orderConfirmedDate)) {
    date.add(1, 'week');
  }

  return date;
};
