import { ShoppingBasket } from 'lucide-react';
import type { HTMLAttributes } from 'react';
import { href, Link } from 'react-router';

import { cn } from '~/utils/css';

interface Props extends HTMLAttributes<HTMLDivElement> {
  itemsInCart: number;
}

export function CartButton(props: Props) {
  const { itemsInCart = 0, className, ...restProps } = props;

  return (
    <div
      className={cn(
        '-mr-2 ml-2 flex w-auto max-w-12 flex-1 flex-shrink justify-end bg-white md:max-w-none md:flex-none',
        className
      )}
      {...restProps}>
      <Link
        to={href('/cart')}
        className='inline-flex items-center justify-center rounded-md p-2 text-sm font-semibold leading-6 text-neutral-500 transition duration-100 hover:bg-neutral-100 hover:text-neutral-600 focus:outline-none lg:pr-3'>
        <span className='sr-only'>Go to cart</span>

        <ShoppingBasket className='h-6 w-6' aria-hidden='true' />

        <span className='ml-2 font-medium'>{itemsInCart}</span>
      </Link>
    </div>
  );
}
