import { PopoverButton } from '@headlessui/react';

import { Menu } from 'lucide-react';

export function Toggle() {
  return (
    <div className='-mx-2 -mr-2 flex w-auto max-w-12 flex-1 flex-shrink md:hidden'>
      <PopoverButton className='inline-flex items-center justify-center rounded-md bg-white p-2 text-neutral-500 hover:bg-neutral-100 hover:text-neutral-600 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-brand-500 dark:bg-neutral-900'>
        <span className='sr-only'>Open main menu</span>

        <Menu className='h-6 w-6' aria-hidden='true' />
      </PopoverButton>
    </div>
  );
}
