import { Cookie, Flame, ListChecks } from 'lucide-react';
import { href } from 'react-router';

import type { MobileLinks, NavLink } from './types';

export const desktopLinks: NavLink[] = [
  {
    name: 'Home',
    href: href('/'),
  },
  {
    name: 'Order Now',
    href: href('/order-now'),
    dropdown: {
      links: [
        {
          name: 'Popular meal options',
          description:
            'Choose from a selection of our favourite and most common meals',
          icon: Flame,
          href: href('/order-now/menu'),
        },
        {
          name: 'Create your own',
          description:
            'Choose your protein, carbohydrates and salad or vegetables for each meal',
          icon: ListChecks,
          href: href('/order-now/create-your-own'),
        },
        {
          name: 'Snacks, Dips and Juices',
          description:
            'Choose your favourite Snacks, Dips and Juices. These extras can be ordered with or without meals',
          icon: Cookie,
          href: href('/order-now/snacks-and-juices'),
        },
      ],
      footer: {
        name: 'Order Now',
        description: 'View all of our packages',
        href: href('/order-now'),
      },
    },
  },
  {
    name: 'FAQ',
    href: href('/faq'),
  },
  {
    name: 'Nutrition',
    href: href('/nutrition'),
  },
  {
    name: 'About',
    href: href('/about'),
  },
  {
    name: 'Contact',
    href: href('/contact'),
  },
];

export const mobileLinks: MobileLinks = {
  packages: [
    {
      name: 'Popular meal options',
      description:
        'Choose from a selection of our favourite and most common meals',
      icon: Flame,
      href: href('/order-now/menu'),
    },
    {
      name: 'Create your own',
      description:
        'Choose your protein, carbohydrates and salad or vegetables for each meal',
      icon: ListChecks,
      href: href('/order-now/create-your-own'),
    },
    {
      name: 'Snacks, Dips and Juices',
      description:
        'Choose your favourite Snacks, Dips and Juices. These extras can be ordered with or without meals',
      icon: Cookie,
      href: href('/order-now/snacks-and-juices'),
    },
  ],
  main: [
    {
      name: 'Home',
      href: href('/'),
    },
    {
      name: 'Order Now',
      href: href('/order-now'),
    },
    {
      name: 'FAQ',
      href: href('/faq'),
    },
    {
      name: 'Nutrition',
      href: href('/nutrition'),
    },
    {
      name: 'About',
      href: href('/about'),
    },
    {
      name: 'Contact',
      href: href('/contact'),
    },
  ],
};
