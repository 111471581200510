import {
  CloseButton,
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition,
} from '@headlessui/react';

import { ChevronDown } from 'lucide-react';
import { Link } from 'react-router';
import { Fragment } from 'react/jsx-runtime';

import type { NavLink } from '../../types';

type Props = {
  link: NavLink;
};

export function DesktopDropdownLink(props: Props) {
  return (
    <Popover className='relative'>
      <PopoverButton className='flex items-center gap-x-2 rounded-md px-1 text-sm font-semibold leading-6 text-neutral-900 focus:outline-none focus:ring-2 focus:ring-brand-500 focus:ring-offset-4'>
        {props.link.name}
        <ChevronDown
          className='h-3 w-3 flex-none text-neutral-400'
          aria-hidden='true'
        />
      </PopoverButton>

      <Transition
        as={Fragment}
        enter='transition ease-out duration-200'
        enterFrom='opacity-0 translate-y-1'
        enterTo='opacity-100 translate-y-0'
        leave='transition ease-in duration-150'
        leaveFrom='opacity-100 translate-y-0'
        leaveTo='opacity-0 translate-y-1'>
        <PopoverPanel className='absolute -left-8 top-full z-10 mt-3 w-screen max-w-md transform-gpu overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-neutral-900/5'>
          <div className='p-4'>
            {props.link.dropdown?.links.map((item) => (
              <div
                key={item.href}
                className='group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-neutral-50'>
                <div className='flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-neutral-50 group-hover:bg-white'>
                  <item.icon
                    className='h-6 w-6 text-neutral-600 group-hover:text-brand-600'
                    aria-hidden='true'
                  />
                </div>

                <div className='flex-auto'>
                  <CloseButton
                    as={Link}
                    to={item.href}
                    className='block font-semibold text-neutral-900'>
                    {item.name}
                    <span className='absolute inset-0' />
                  </CloseButton>

                  <p className='mt-1 text-neutral-600'>{item.description}</p>
                </div>
              </div>
            ))}
          </div>

          <div className='relative divide-neutral-900/5 bg-neutral-50 p-4'>
            {props.link.dropdown?.footer ? (
              <div className='group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-neutral-100'>
                <div className='flex-auto rounded-lg'>
                  <CloseButton
                    as={Link}
                    to={props.link.dropdown.footer.href}
                    className='block font-semibold text-neutral-900'>
                    {props.link.dropdown.footer.name}{' '}
                    <span className='ml-1'>&rarr;</span>
                    <span className='absolute inset-0' />
                  </CloseButton>

                  <p className='text-neutral-600'>
                    {props.link.dropdown.footer.description}
                  </p>
                </div>
              </div>
            ) : null}
          </div>
        </PopoverPanel>
      </Transition>
    </Popover>
  );
}
