import { Link } from 'react-router';

type Props = {
  name: string;
  href: string;
};

export function DesktopTopLink(props: Props) {
  return (
    <Link
      to={props.href}
      className='px-1 text-sm font-semibold leading-6 text-neutral-900'>
      {props.name}
    </Link>
  );
}
