import type { HTMLAttributes } from 'react';
import { href, Link } from 'react-router';

import { cn } from '~/utils/css';

export function Logo(props: HTMLAttributes<HTMLDivElement>) {
  const { className, ...restProps } = props;

  return (
    <div
      className={cn(
        'flex flex-1 justify-center md:flex-none md:flex-shrink md:justify-start',
        className
      )}
      {...restProps}>
      <Link to={href('/')} className='-m-1.5 p-1.5'>
        <span className='sr-only'>Shape Up Meal Prep</span>

        <img
          className='h-12 w-auto md:h-12'
          src='/images/sump-logo.svg'
          alt='Shape Up Meal Prep Logo'
        />
      </Link>
    </div>
  );
}
